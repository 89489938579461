import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import bgImage from "assets/images/bg-digital.jpg";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import "./legal.css";

function Legal() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                action={{
                    type: "internal",
                    route: "#contact",
                    label: "Nous contacter",
                    icon: "phone",
                    color: "info",

                }}
                sticky
            />
            <MKBox
                minHeight="35vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["2xl"],
                                },
                            })}
                        >
                            Mentions légales
                        </MKTypography>


                    </Grid>
                </Container>
            </MKBox>
            <Container className="contain">

                Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site Lymp Cloud l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.

                <Grid className="paragraphe">

                    <MKTypography variant="h4">Edition du site</MKTypography>

                    Le présent site, accessible à l’URL https://lympcloud.com/ (le « Site »), est édité par :

                    Lymp Cloud SARL , société au capital de 1000 euros, inscrite au R.C.S. de MONTPELLIER sous le numéro RCS MONTPELLIER 981078256, dont le siège social est situé au 6 bis boulevard Berthelot 34000 Montpellier.

                    Le numéro individuel TVA de l’éditeur est : FR58981078256.

                </Grid>

                <Grid className="paragraphe">

                    <MKTypography variant="h4">Hébergement</MKTypography>

                    Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1, (contact téléphonique ou email : 1007).

                </Grid>

                <Grid className="paragraphe">

                    <MKTypography variant="h4">Nous contacter</MKTypography>

                    Par téléphone : +33763305605 <br />
                    Par email : contact@lympcloud.com <br />
                    Par courrier : 6 bis boulevard Berthelot 34000 Montpellier

                </Grid>


                <Grid className="paragraphe">

                    <MKTypography variant="h4">Données personnelles</MKTypography>

                    Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée, disponible depuis la section "Charte de Protection des Données Personnelles", conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 («RGPD»).
                </Grid>




            </Container>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default Legal;
