import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections dans l'ordre:
import Counters from "pages/Home/sections/Counters";
import Services from "pages/Home/sections/Services";
import Expertise from "./sections/Expertise";
import Realisations from "pages/Home/sections/Realisations";
import Team from "pages/Home/sections/Team";
import SEO from "pages/Home/sections/SEO";
import Mobile from "./sections/Mobile";

// Presentation page components
import Contact from "pages/LandingPages/ContactUs";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
//import bgImage from "assets/images/entreprise-internet-compressed.jpg";
import bgImage from "assets/images/bg-digital-min.jpg"
function Home() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "#contact",
          label: "Nous contacter",
          icon: "phone",
          color: "info",

        }}
        sticky
      />
      <a id="home"></a>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{

          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.4)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={1}
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              style={{ textAlign: "center" }}
            >
              Votre site internet sans engagement et sans frais de mise en service !{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 1 }}
              mt={1}
            >
              Vous voulez être visible sur internet ? Choisissez proximité et réactivité en faisant appel à une entreprise locale pour réaliser vos projets digitaux !
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        
        <Services />
        <a id="services"></a>
        <SEO />
        <Mobile />
        <a id="demarche"></a>
        <Expertise />
        <a id="equipe"></a>
        <Team />
        <a id="contact"></a>
        <Contact/>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
