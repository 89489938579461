// @mui material components
import Grid from "@mui/material/Grid";
import React, { useState, Component } from 'react';
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import SteylorBasis from "assets/images/steylor.jpg";
import SteylorRegister from "assets/images/register_steylor.jpg";
import SteylorDashboard from "assets/images/dashboard_steylor.jpg";
import SteylorNotifs from "assets/images/notifs_steylor.JPG";
import SteylorPrice from "assets/images/price_steylor.JPG";
import SteylorConfigure from "assets/images/configure_steylor.JPG";
import SteylorSignIn from "assets/images/signin_steylor.JPG";

import BlaBlaMeuhCreate from "assets/images/blablameuh_create.jpg";
import BlaBlaMeuhPropose from "assets/images/blablameuh_propose.jpg";
import BlaBlaMeuhConfirmation from "assets/images/blablameuh_confirmation.jpg";
import BlaBlaMeuhDatepicker from "assets/images/blablameuh_datepicker.jpg";
import BlaBlaMeuhLogin from "assets/images/blablameuh_login.jpg";
import BlaBlaMeuhUser from "assets/images/blablameuh_user.jpg";
import BlaBlaMeuhTarifs from "assets/images/blablameuh_tarifs.jpg";

import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css"
import bgImage from "assets/images/bg-digital.jpg";


// Don't forget to include the css in your page

// Using webpack or parcel with a style loader
// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

// Using html tag:
// <link rel="stylesheet" href="<NODE_MODULES_FOLDER>/react-responsive-carousel/lib/styles/carousel.min.css"/>

function RealisationsDescription() {

  const imagesSteylor = [
    {
      original: SteylorBasis,
      thumbnail: SteylorBasis,
    },
    {
      original: SteylorRegister,
      thumbnail: SteylorRegister,
    },
    {
      original: SteylorSignIn,
      thumbnail: SteylorSignIn,
    },
    {
      original: SteylorPrice,
      thumbnail: SteylorPrice,
    },
    {
      original: SteylorDashboard,
      thumbnail: SteylorDashboard,
    },
    {
      original: SteylorConfigure,
      thumbnail: SteylorConfigure,
    },
    {
      original: SteylorNotifs,
      thumbnail: SteylorNotifs,
    },
  ];

  const imagesBlaBlaMeuh = [
    {
      original: BlaBlaMeuhCreate,
      thumbnail: BlaBlaMeuhCreate,
    },
    {
      original: BlaBlaMeuhPropose,
      thumbnail: BlaBlaMeuhPropose,
    },
    {
      original: BlaBlaMeuhConfirmation,
      thumbnail: BlaBlaMeuhConfirmation,
    },
    {
      original: BlaBlaMeuhDatepicker,
      thumbnail: BlaBlaMeuhDatepicker,
    },
    {
      original: BlaBlaMeuhLogin,
      thumbnail: BlaBlaMeuhLogin,
    },
    {
      original: BlaBlaMeuhUser,
      thumbnail: BlaBlaMeuhUser,
    },
    {
      original: BlaBlaMeuhTarifs,
      thumbnail: BlaBlaMeuhTarifs,
    },
  ];

    return (
      <>
      <MKBox position="fixed" top="0.5rem" width="100%" style={{zIndex:"9999"}}>
        <DefaultNavbar
          routes={routes}
        />
      </MKBox>

      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
              })}
            >
              Nos réalisations
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Nos derniers projets ont su satisfaire des cahiers des charges exigeants. Voici un présentation de nos derniers projets.
            </MKTypography>
            <MKButton onClick={() => window.location.replace("presentation/#contact")} color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              Démarrer mon projet
            </MKButton>

          </Grid>
        </Container>
      </MKBox>
      
      <div style={{marginTop:"250px", width:"70%", marginLeft:"auto", marginRight:"auto"}}>

        <MKBox
          variant="gradient"
          bgColor="info"
          coloredShadow="info"
          borderRadius="lg"
          p={2}
          mx={2}
          mt={-3}
          style={{}}
        >
          <MKTypography variant="h3" color="white">
            <div style={{textAlign:"center"}}>Steylor.com</div>
          </MKTypography>
          
        </MKBox>
        <p style={{marginTop:"30px", textAlign:"justify"}}>La start-up Steylor, nouvellement créée, propose des services de prospection automatisée sur les réseaux sociaux. Le site internet que nous avons réalisé fait à la fois office de site vitrine mais constitue surtout le produit principal de l'entreprise. Son interface permet d'utiliser des robots automatisant l'envoie de messages privés sur Twitter.
        Pour cela, l'utilisateur final navigue sur l'interface du site afin d'acheter une des 3 formules d'envoie de messages qui permettent respectivement d'envoyer 100, 500 et 1000 messages par jours.
        Une fois la formule choisie et payée, le client peut utiliser son robot via l'interface moderne que nous avons conçue.
        Ce projet est un condensé de notre savoir-faire puisqu'il se compose d'un site vitrine, d'un site sur-mesure avec un espace utilisateur (inscription/connexion) et une solution très spéficique et complexe (le robot automatisant la prospection sur les réseaux sociaux). La partie serveur n'est pas en reste puisque nous avons construit pas moins de 3 serveurs, deux bases de données ainsi qu'une application serveur pour faire fonctionner l'ensemble.
        </p>
        <div style={{marginTop:"40px"}}><ImageGallery showPlayButton={false} items={imagesSteylor} /></div>

      </div>
      
      <div style={{marginTop:"180px", width:"70%", marginLeft:"auto", marginRight:"auto"}}>

        <MKBox
          variant="gradient"
          bgColor="info"
          coloredShadow="info"
          borderRadius="lg"
          p={2}
          mx={2}
          mt={-3}
          style={{}}
        >
          <MKTypography variant="h3" color="white">
            <div style={{textAlign:"center"}}>Yakikivient</div>
          </MKTypography>
          
        </MKBox>
        <p style={{marginTop:"30px", textAlign:"justify"}}>Projet initié au sein de l'école des Mines d'Alès, l'application Yakikivient a pour but de proposer une solution pour gérer les covoiturages entre élèves et personnels de l'école. Cette application iPhone et Android propose un design moderne et soigné et plusieurs fonctionnalités innovantes. La partie serveur de l'application comporte une base de données et une API afin de rentre l'application disponible grâce à une simple connexion internet.</p>
        <div style={{marginTop:"40px"}}><ImageGallery showPlayButton={false} items={imagesBlaBlaMeuh} /></div>

      </div>

      <MKBox style={{marginTop:"7rem"}} pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      </>
    );
 
}

export default RealisationsDescription;
