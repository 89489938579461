// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

/*
  {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/creativetimofficial",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
*/

export default {
  brand: {
    name: "Material Kit 2",
    image: logoCT,
    route: "/",
  },
  socials: [
  
  ],
  menus: [
    {
      name: "Plan du site",
      items: [
        { name: "Présentation", href: "/presentation" },
        { name: "Services", href: "/presentation/#services" },
        { name: "Equipe", href: "/presentation/#equipe" },
      ],
    },
    {
      name: "En savoir plus",
      items: [
        { name: "Notre démarche", href: "/services" },
        { name: "Informations légales", href: "/legal" },
        { name: "CGU", href: "/cgu" },
        
      ],
    },
    {
      name: "Votre projet",
      items: [
        { name: "Nous contacter", href: "/contact" },
      
      ],
    },
    {
      name: "Contact",
      items: [
        { name: "6 bis boulevard Berthelot bureau 3 34000 Montpellier", href: "./" },
        { name: "Mail: contact@lympcloud.com", href: "mailto:contact@lympcloud.com" },
        { name: "Tel: +33763305605", href: "tel:+33763305605" },
        { name: "Tel: +33650896819", href: "tel:+33650896819" },
        
      ],
    },
   
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
     
      <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        ©2024 - Lymp Cloud
      </MKTypography>
      .
    </MKTypography>
  ),
};
