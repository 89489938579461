import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
//import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/pc_001-min.jpg";
//import bgBack from "assets/images/rotating-card-bg-back.jpeg";
import bgFront from "assets/images/meeting_001-min.jpg";
import "./style.css";

function Services() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Nous réalisons tous vos projets
                  </>
                }
                description="Chaque entreprise a aujourd'hui besoin de se digitaliser."
              />
              <RotatingCardBack
                image={bgBack}
                title="C'est parti !"
                description="Vous aussi vous souhaitez faire appel à nos services pour bâtir votre solution digitale ? "
                action={{
                  type: "internal",
                  route: "#contact",
                  label: "contactez-nous",
                  
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
              <div className="isMobileWebSite"></div>
                <DefaultInfoCard
                  icon="website"
                  title="Sites Internet"
                  description="Du site vitrine simple au site e-commerce, nous réalisons votre solution web sur mesure."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="search"
                  title="Référencement"
                  description="Nous mettons en avant votre entreprise sur les moteurs de recherche grâce au référencement."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="smartphone"
                  title="Applications mobiles"
                  description="Que ce soit sur Android ou iOS, nous réalisons votre projet d'application simple ou complexe."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="more"
                  title="Solutions sur mesure"
                  description="Un besoin bien précis ? Pas de souci, nous pouvons réaliser votre site ou application comme vous l'imaginez."
                />
              </Grid>
            </Grid>
          </Grid>
          </Grid>

    
      </Container>



    </MKBox>
  );
}

export default Services;
