import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import emailjs from '@emailjs/browser';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
import grilleTarifaire from "assets/images/computerFondEcran.jpg";
import { useState } from "react";

// CSS
import "./contact.css";


function ContactUs() {

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [message, setMessage] = useState();
  const [buttonLabel, setButtonLabel] = useState("Envoyer");

  const [button, setButton] = useState(false);

  const checkFields = function (field, value) {

    if (field != "phone" && !value) {
      setButton(false);
      return;
    }

    if (name && email && message) {
      setButton(true);
      return;
    }

    setButton(false);
  }

  const handleField = async function (field, value) {

    switch (field) {
      case "name":
        await setName(value);
        break;
      case "email":
        await setEmail(value);
        break;
      case "phone":
        await setPhone(value);
        break;
      case "message":
        await setMessage(value);
        break;
      default:
        break;
    }

    checkFields(field, value);

  }


  const sendEmail = function (name, email, phone, message) {

    setButtonLabel("Envoie en cours ...");
    setButton(false);

    const templateParams = {
      name: name,
      email: email,
      phone: phone,
      message: message
    }

    emailjs.send('service_3vj5rzj', 'template_2i5dwsk', templateParams, 'uzaBpG9-hN3AbnHdm')
      .then((result) => {
        setButtonLabel("Votre message a été envoyé. Nous vous répondrons dans les plus brefs délais.");
      }, (error) => {
        alert("Votre message n'a pas pu être envoyé. Veuillez réessayer.");
        console.log(error.text);
      });

  }

  return (
    <>

      <MKBox component="section" pt={1} my={6}>
        <Container sx={{marginTop:"30px"}}>

          <Grid container spacing={3}>

          <Grid item xs={12} lg={6}>
              <MKBox position="relative">
                <MKBox component="img" src={grilleTarifaire} width="100%" />
              </MKBox>
            </Grid>

            <Grid className="grilleTarifaireSpace" item xs={12} lg={6}>

                  <MKBox
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                    borderRadius="lg"
                    p={2}
                    mx={2}
                    mt={-3}
                  >
                    <MKTypography variant="h4" color="white">
                      Vous avez un projet ? Contactez-nous
                    </MKTypography>
                  </MKBox>
                  <MKBox p={3}>
                    <MKTypography variant="body2" color="text" mb={3}>

                      *Indiquer votre numéro de téléphone si vous souhaitez être rappelé.
                    </MKTypography>
                    <MKBox sx={{ fontSize: "16px" }} width="100%" component="form" method="post" autoComplete="off">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            variant="standard"
                            label="Votre nom"
                            required
                            onChange={(e) => handleField("name", e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            type="email"
                            variant="standard"
                            label="Email"
                            required
                            onChange={(e) => handleField("email", e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <MKInput
                            type="phone"
                            variant="standard"
                            label="Numéro de téléphone"
                            onChange={(e) => handleField("phone", e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MKInput
                            variant="standard"
                            required
                            label="Que pouvons-nous faire pour vous ?"
                            placeholder=""
                            onChange={(e) => handleField("message", e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            multiline
                            fullWidth
                            rows={6}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                        <MKButton disabled={!button} onClick={() => sendEmail(name, email, phone, message)} variant="gradient" color="info">
                          {buttonLabel}
                        </MKButton>
                      </Grid>
                    </MKBox>
                  </MKBox>
                
            </Grid>
          </Grid>

         
        </Container>
      </MKBox>

    </>
  );
}

export default ContactUs;
