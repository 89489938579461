import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import bgImage from "assets/images/bg-digital.jpg";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import "./cgu.css";

function CGU() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                action={{
                    type: "internal",
                    route: "#contact",
                    label: "Nous contacter",
                    icon: "phone",
                    color: "info",

                }}
                sticky
            />
            <MKBox
                minHeight="35vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["2xl"],
                                },
                            })}
                        >
                            Conditions générales d'utilisation
                        </MKTypography>


                    </Grid>
                </Container>
            </MKBox>
            <Container className="contain">

                Date de dernière mise à jour : 08/11/2023
                <Grid className="paragraphe">

                    <MKTypography variant="h4">Article 1 – MENTIONS LÉGALES</MKTypography>

                    Le présent site, accessible à l’URL https://lympcloud.com/ (le « Site »), est édité par :

                    Lymp Cloud SARL , société au capital de 1000 euros, inscrite au R.C.S. de MONTPELLIER sous le numéro RCS MONTPELLIER 981078256, dont le siège social est situé au 6 bis boulevard Berthelot 34000 Montpellier.

                    Le numéro individuel TVA de l’éditeur est : FR58981078256.

                    (Ci-après désigné l’« Exploitant »).

                    Le numéro individuel TVA de l’Exploitant est :  FR58981078256 .

                    Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1, (contact téléphonique ou email : 1007).

                    L’Exploitant peut être joint au numéro de téléphone suivant +33763305605 et à l’adresse mail suivante contact@lympcloud.com.


                </Grid>

                <Grid className="paragraphe">

                    <MKTypography variant="h4">Article 2 – DESCRIPTION DU SERVICE</MKTypography>

                    Le Site est mis à la disposition de toute personne accédant au site (l’ « Utilisateur ») pour prendre connaissance des activités de la société, prendre contact avec celle-ci et gérer ses services.

                    Le Site n’est pas un site marchand et ne propose aucune transaction commerciale à distance.

                    L’Utilisateur demeure responsable des modalités et des conséquences de son accès au Site notamment par l’Internet. Cet accès peut impliquer le paiement de frais à des prestataires techniques tels que notamment des fournisseurs d’accès à l’Internet, lesquels demeurent à sa charge. En outre, l’Utilisateur devra fournir et être entièrement responsable des équipements nécessaires afin de se connecter au Site.

                    L’Utilisateur reconnait avoir vérifié que la configuration informatique qu’il utilise est sécurisée et en état de fonctionnement.

                    Les informations et services proposés par le Site sont gratuits, et accessibles 24h/24h et 7jours/7 jours, sauf en cas de force majeure, de pannes informatiques, d’opérations de maintenance ou de problèmes liés aux réseaux de télécommunications.
                </Grid>

                <Grid className="paragraphe">

                    <MKTypography variant="h4">Article 3 – PROPRIÉTÉ INTELLECTUELLE ET LICENCE D’UTILISATION DU SITE
                        E</MKTypography>

                    L’Exploitant est seul titulaire de tous les éléments présents sur le Site, notamment et sans limitation, tous textes, fichiers, images animées ou non, photographies, vidéos, logos, dessins, modèles, logiciels, marques, identité visuelle, base de données, structure du Site et tous autres éléments de propriété intellectuelle et autres données ou informations (ci-après, les « Éléments ») qui sont protégés par les lois et règlements français et internationaux relatifs notamment à la propriété intellectuelle.

                    En conséquence, aucun des Éléments du Site ne pourra en tout ou partie être modifié, reproduit, copié, dupliqué, vendu, revendu, transmis, publié, communiqué, distribué, diffusé, représenté, stocké, utilisé, loué ou exploité de toute autre manière, à titre gratuit ou onéreux, par un Utilisateur ou par un tiers, quel que soient les moyens et/ou les supports utilisés, qu’ils soient connus ou inconnus à ce jour, sans l’autorisation préalable exprès et écrite de l’Exploitant au cas par cas, et l’Utilisateur est seul responsable de toute utilisation et/ou exploitation non autorisée.


                </Grid>


                <Grid className="paragraphe">

                    <MKTypography variant="h4">ARTICLE 4 – RESPONSABILITÉ ET GARANTIE</MKTypography>

                    L’Utilisateur reconnaît que les caractéristiques et les contraintes d'Internet ne permettent pas de garantir la sécurité, la disponibilité et l'intégrité des transmissions de données sur Internet. Ainsi, l’Exploitant ne garantit pas que le Site et ses services fonctionneront sans interruption ni erreur de fonctionnement. En particulier, leur exploitation pourra être momentanément interrompue pour cause de maintenance, de mises à jour ou d'améliorations techniques, ou pour en faire évoluer le contenu et/ou leur présentation.

                    L’Exploitant ne peut être tenu pour responsable de l'utilisation qui serait faite du Site et de ses Éléments par les Utilisateurs en violation des présentes Conditions Générales d’Utilisation et des dommages directs ou indirects que cette utilisation pourrait causer à un Utilisateur ou à un tiers. En particulier, l’Exploitant ne peut être tenu pour responsable des fausses déclarations faites par un Utilisateur et de son comportement vis-à-vis des tiers. Dans le cas où la responsabilité de l’Exploitant serait recherchée à raison d'un tel comportement d’un de ses Utilisateurs, ce dernier s'engage à garantir l’Exploitant contre toute condamnation prononcée à son encontre ainsi qu’à rembourser l’Exploitant de l’ensemble des frais, notamment les honoraires d’avocats, engagés pour sa défense.

                </Grid>


                <Grid className="paragraphe">

                    <MKTypography variant="h4">ARTICLE 5 – DONNÉES PERSONNELLES </MKTypography>

                    Pour davantage d’informations concernant l’utilisation de données à caractère personnel par l’Exploitant, veuillez lire attentivement la Charte sur le respect de la vie privée (la « Charte »). Vous pouvez à tout moment consulter cette Charte sur le Site.

                </Grid>


                <Grid className="paragraphe">

                    <MKTypography variant="h4">Article 6 – LIENS HYPERTEXTES </MKTypography>

                    Les liens hypertextes disponibles sur le Site peuvent renvoyer vers des sites tiers non édités par l’Exploitant. Ils sont fournis uniquement pour la convenance de l’Utilisateur, afin de faciliter l’utilisation des ressources disponibles sur l’Internet. Si l’Utilisateur utilise ces liens, il quittera le Site et acceptera alors d’utiliser les sites tiers à ses risques et périls ou le cas échéant conformément aux conditions qui les régissent.

                    L’Utilisateur reconnait que l’Exploitant ne contrôle ni ne contribue en aucune manière à l’élaboration des conditions d’utilisation et/ou du contenu s’appliquant à ou figurant sur ces sites tiers.

                    En conséquence, l’Exploitant ne saurait être tenu responsable de quelque façon que ce soit du fait de ces liens hypertextes.

                    En outre, l’Utilisateur reconnait que l’Exploitant ne saurait cautionner, garantir ou reprendre à son compte tout ou partie des conditions d’utilisation et/ou du contenu de ces sites tiers.

                    Le Site peut également contenir des liens hypertextes promotionnels et/ou bandeaux publicitaires renvoyant vers des sites tiers non édités par l’Exploitant.

                    L’Exploitant invite l’Utilisateur à lui signaler tout lien hypertexte présent sur le Site qui permettrait d’accéder à un site tiers proposant du contenu contraire aux lois et/ou aux bonnes mœurs.

                    L’Utilisateur ne pourra pas utiliser et/ou insérer de lien hypertexte pointant vers le site sans l’accord écrit et préalable de l’Exploitant au cas par cas.
                </Grid>

                <Grid className="paragraphe">

                    <MKTypography variant="h4">ARTICLE 7 –  DISPOSITIONS GÉNÉRALES</MKTypography>

                    <Grid className="paragraphe">
                        <strong>INTEGRALITÉ DE L’ACCORD DES PARTIES</strong>
                        <br/>

                        Les présentes Conditions Générales d’Utilisation constituent un contrat régissant les relations entre l’Utilisateur et l’Exploitant. Elles constituent l'intégralité des droits et obligations de l’Exploitant et de l’Utilisateur relatifs à leur objet. Si une ou plusieurs stipulations des présentes Conditions Générales d’Utilisation étaient déclarées nulles en application d'une loi, d'un règlement ou à la suite d'une décision définitive d'une juridiction compétente, les autres stipulations garderont toute leur force et leur portée. En outre, le fait pour une des parties aux présentes Conditions Générales d’Utilisation de ne pas se prévaloir d'un manquement de l'autre partie à l'une quelconque des dispositions des présentes Conditions Générales d’Utilisation ne saurait s'interpréter comme une renonciation de sa part à se prévaloir dans l'avenir d'un tel manquement.
                    </Grid>

                    <Grid className="paragraphe">
                        <strong>MODIFICATIONS DES CONDITIONS D'UTILISATION</strong>
                        

                        L’Exploitant se réserve le droit de modifier à tout moment et sans préavis le contenu du Site ou des services qui y sont disponibles, et/ou de cesser de manière temporaire ou définitive d’exploiter tout ou partie du Site.

                        En outre, l’Exploitant se réserve le droit de modifier à tout moment et sans préavis la localisation du Site sur l’Internet, ainsi que les présentes Conditions Générales d’Utilisation. L’Utilisateur est donc tenu par conséquent de se reporter aux présentes Conditions Générales d’Utilisation avant toute utilisation du Site. En cas de modifications matérielles, l’Utilisateur sera informé au moyen d'un email et d’un avertissement sur le Site avant la mise en application de la modification.

                        L’Utilisateur reconnait que l’Exploitant ne saurait être tenu responsable de quelque manière que ce soit envers lui ou tout tiers du fait de ces modifications, suspensions ou cessations.
                    </Grid>

                    <Grid className="paragraphe">
                        <strong>RÉCLAMATION</strong>
                        <br/>

                        En cas de litige, vous devez vous adresser en priorité au service client de l'entreprise aux coordonnées suivantes :

                        Lymp Cloud
                        6 bis bd Berthelot Bureau 3 34000 Montpellier
                        contact@lympcloud.com
                    </Grid>

                    <Grid className="paragraphe">

                        <strong>DROIT APPLICABLE</strong>
                        <br/>

                        Ces Conditions Générales d’Utilisation sont régies, interprétées et appliquées conformément au droit français.
                    </Grid>

                    <Grid className="paragraphe">
                        <strong>ACCEPTATION DES CONDITIONS GÉNÉRALES PAR L’UTILISATEUR</strong>
                        <br/>

                        L’Utilisateur reconnait avoir lu attentivement les présentes Conditions Générales d’Utilisation.

                        En s’inscrivant sur le Site, l’Utilisateur confirme avoir pris connaissance des Conditions Générales d'Utilisation et les accepter, le rendant contractuellement lié par les termes des présentes Conditions Générales d’Utilisation.

                        Les Conditions Générales d’Utilisation applicables à l’Utilisateur sont celles mises en ligne sur le Site. En cas de modification, l’Exploitant publiera ces changements sur le Site pour que l’Utilisateur sache quelles informations sont collectées, comment elles sont utilisées, dans quelles circonstances, et le cas échéant, comment elles sont divulguées. En cas de modifications matérielles, l’Utilisateur sera informé au moyen d'un email et d’un avertissement sur le Site avant la mise en application de la modification.
                    </Grid>
                </Grid>




            </Container>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default CGU;
