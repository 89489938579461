/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Material Kit 2 React components
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-digital.jpg";
import conseilImage from "assets/images/flexiple/mentor.png";
import devImage from "assets/images/flexiple/dev.png";
import fixImage from "assets/images/flexiple/fix.png";
import infraImage from "assets/images/flexiple/infra.png";


function Services() {
  return (
    <>

      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/presentation/#contact",
          label: "Nous contacter",
          color: "info",
          icon:"phone"
        }}
        sticky
      />

      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
              })}
            >
              Notre démarche
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Nous apportons à votre entreprise notre expertise au travers de différents services de conseil, d'accompagnement, de développement et d'infrastructure.
            </MKTypography>
            <MKButton onClick={() => window.location.replace("presentation/#contact")} color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              Démarrer mon projet
            </MKButton>

          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        <Container >

          <MKBadge
            variant="contained"
            color="info"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography sx={{ marginBottom: "0.8em" }} variant="h2" fontWeight="bold">
            Conseil
          </MKTypography>

          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>


              <MKTypography variant="body3" >
                Chaque entreprise a besoin d'être conseillée dans ses choix technologiques. Ceux-ci peuvent être cruciaux dans le développement de votre activité. Faire appel à nos services, c'est obtenir une expertise complète et un accompagnement dans la digitalisation de votre entreprise. <br></br>Nous analysons vos besoins pour vous proposer les solutions les plus économiques et performantes. Nous avançons toujours main dans la main avec nos clients en prônant le bon relationnel et la disponibilité.
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>

              <MKBox
                minHeight="30vh"
                width="100%"
                sx={{
                  backgroundImage: `url(${conseilImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "grid",
                  placeItems: "center",
                }}
              ></MKBox>

            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="travel_explore"
                      title="Evaluation de vos besoins"
                      description="Nous échangeons pour prendre connaissance de vos besoins. Nous vous orientons vers les solutions les mieux adaptées à votre activité: site web, site vitrine, application ou autre."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="architecture"
                      title="Proposition d'une solution"
                      description="Ensemble, nous établissons un cahier des charges pour répondre à votre besoin. Nous vous fournissons des maquettes et prototypes de ce qui sera réalisé."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="account_balance_wallet"
                      title="Amélioration de l'existant"
                      description="Si vous possédez déjà des solutions digitales ou une infrastructure, nous pouvons les analyser pour les améliorer et les moderniser. Nous vous guidons dans vos choix pour être au plus près de vos besoins."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="computer"
                      title="Choix des technologies"
                      description="Nous vous proposons les meilleures technologies qui permettront de réaliser le projet. Nous travaillons avec les technologies les plus récentes et les plus avantageuses pour vous."
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>

          </Grid>

        </Container>



        <Container sx={{ marginTop: '2em' }}>

          <MKBadge
            variant="contained"
            color="info"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography sx={{ marginBottom: "1em" }} variant="h2" fontWeight="bold">
            Réalisation de votre projet
          </MKTypography>

          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>


              <MKTypography variant="body3" >
                Nous utilisons les meilleures technologies actuelles pour vous fournir le produit adapté. Que ce soit d'un point de vue visuel ou fonctionnel, nous créons la solution qu'il vous faut. Nos méthodes de travail flexibles nous permettent d'avancer ensemble.
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
              <MKBox
                minHeight="30vh"
                width="100%"
                sx={{
                  backgroundImage: `url(${devImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "grid",
                  placeItems: "center",
                }}
              ></MKBox>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="handyman"
                      title="Développement de la solution"
                      description="Nous réalisons votre projet en suivant des méthodes de travail modernes vous permettant d'être pleinement impliqué dans le suivi du projet. Pour cela nous utilisons la méthode Agile."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="forum"
                      title="Travail flexible"
                      description="la méthode de travail Agile nous permet d'interagir régulièrement avec vous. Nous vous montrons l'avancement du projet en temps réel. Vos besoins évoluent en cours de route ? Pas de problème, nous faisons évoluer le cahier des charges et revoyons la priorité des tâches."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="download"
                      title="Livraison de la solution"
                      description="Une fois le projet terminé, nous nous occupons de son déploiement au sein de votre organisation afin que la solution soit rapidement utilisable par vontre entreprise. "
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>

          </Grid>

        </Container>



        <Container sx={{ marginTop: '2em' }}>

          <MKBadge
            variant="contained"
            color="info"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography sx={{ marginBottom: "0.8em" }} variant="h2" fontWeight="bold">
            Maintenance
          </MKTypography>

          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>


              <MKTypography variant="body3">
                Les solutions digitales ont un besoin constant d'attention, même après leur livraison. La maintenance regroupe toutes les actions nécessaires pour maintenir votre produit à niveau dans le temps. <br></br> Nous fournissons la maintenance de votre produit après la livraison, ou plus longtemps selon vos besoins grâce à notre service de support inclus. Nous sommes donc là pour vous accompagner même après la fin du projet.

              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <MKBox
                minHeight="30vh"
                width="100%"
                sx={{
                  backgroundImage: `url(${fixImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "grid",
                  placeItems: "center",
                }}
              ></MKBox>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="build"
                      title="Maintence post-livraison"
                      description="La maintenance post-livraison est incuse dans toutes les solutions que nous proposons. Celle-ci comprend la correction de problèmes pouvant survenir dans les semaines ou les mois suivant la livraison, ainsi que les derniers ajustements."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="construction"
                      title="Maintenance long-terme"
                      description="En option, nous vous proposons la maintenance de votre produit au-delà du délais prévu par la maintenance post-livraison."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="upgrade"
                      title="Evolution"
                      description="Votre site, application, ou autre produit a parfois besoin d'évoluer pour être mis au goût du jour ou pour y ajouter de nouvelles fonctionnalités. Nous restons à votre écoute pour l'amélioration de votre solution."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="support_agent"
                      title="Support"
                      description="Notre service de support apporte une réponse à touts vos questions après la livraison de votre projet. Réponse rapide garantie. Support disponible à vie."
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>

          </Grid>

        </Container>



        <Container sx={{ marginTop: '2em' }}>

          <MKBadge
            variant="contained"
            color="info"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography sx={{ marginBottom: "0.8em" }} variant="h2" fontWeight="bold">
            Infrastructure
          </MKTypography>

          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>


              <MKTypography variant="body3">
                Les applications ont besoin d'une infrastructure pour fonctionner. Une infrastructure est un ensemble d'éléments tels que des serveurs, bases de données, réseaux ou domaines liés entre eux. Par exemple, un site web a besoin d'être hébergé sur un serveur pour être accessible sur internet. Il a de plus besoin d'un nom de domaine pour être accessible par vos clients. Selon vos besoins, nous mettons en place des infrastructures pour faire fonctionner vos applications.
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <MKBox
                minHeight="30vh"
                width="100%"
                sx={{
                  backgroundImage: `url(${infraImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "grid",
                  placeItems: "center",
                }}
              ></MKBox>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="gite"
                      title="Hébergement"
                      description="Les sites ou les applications ont besoin d'être hébergés chez un fournisseur d'hébergement tiers, afin d'être disponible sur internet. Héberger une solution digitale peut vite devenir fastidieux. C'est pourquoi nous vous proposons ce service pour tous vos projets."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="storage"
                      title="Bases de données"
                      description="Certains sites et applications ont besoin de stocker une certaines quantité de données pour votre activité et vos clients. Héberger une base de données chez un fournisseur tiers est alors nécessaire. Nous vous proposons ce services pour tous vos projets nécessitant une base de données."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="language"
                      title="Domaine et e-mail"
                      description="Tout site internet se doit d'être rattaché à un nom de domaine www. Le nom de domaine est le point d'accès de votre entreprise sur internet et est donc indispensable dans tout projet digital et web. Des adresses mail peuvent aussi être ajoutées à votre nom de domaine."
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="dns"
                      title="Serveurs"
                      description="Un besoin d'infrastructure spécifique ? Nos solutions serveurs vous offrent des machines délocalisées et personnalisées vous permettant d'héberger n'importe quelle site ou application. Nous vous fournissons votre serveur clés en main pour vous bâtir une réelle infrastructure d'entreprise."
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>

          </Grid>

        </Container>


        <Newsletter showButton={true} />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Services;
