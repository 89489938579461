// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components


import palette from "assets/images/icones/palette.png";
import multiplatform from "assets/images/icones/multiplatform.png"
import shopbag from "assets/images/icones/shopbag.png"

import mobile from "assets/images/illustrations/mobile-min.png";

import Fade from 'react-reveal/Zoom';
import "./style.css";

import "./Mobile.css";

function Mobile() {


    return (
        <MKBox component="section" py={6} sx={{ marginTop: "-40px", marginBottom: "-30px"}}>
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    flexDirection="column"
                    alignItems="center"
                    sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
                >
                    <MKBadge
                        variant="contained"
                        color="info"
                        badgeContent="Mobile"
                        container
                        sx={{ mb: 2 }}
                    />
                    <MKTypography variant="h2" fontWeight="bold">
                        Création d'applications mobiles
                    </MKTypography>
                </Grid>
            </Container>


            <Container className="main" sx={{ mt: { xs: 8, lg: 16 }}}>

                <Grid className="content" sx={{ marginTop: "-80px"}}>

                    <Grid className="mobileLeft" sx={{
                        backgroundImage: `url(${mobile})`,
                    }}>

                    </Grid>

                    <Grid className="mobileRight isMobile100SurMesure creationSite">


                        <Fade>

                            <div className="uline">

                                <Grid className="mobileicone" sx={{
                                    backgroundImage: `url(${palette})`,
                                }}></Grid>

                                <MKTypography className="textuline" variant="body2"> <strong>100% sur mesure</strong> <br></br> Pour répondre à vos exigences, nous réalisons votre projet d'application ergonomique au design moderne et accessible pour vos utilisateurs. </MKTypography>


                            </div>

                        </Fade>



                        <Fade>

                            <div className="uline">

                                <Grid className="mobileicone" sx={{
                                    backgroundImage: `url(${multiplatform})`,
                                }}></Grid>

                                <MKTypography className="textuline" variant="body2"><strong>Multi plateformes</strong> <br></br>Nos applications fonctionnent aussi bien sous Android que sous iOs, sur smartphones et tablettes et sont ainsi accessibles au plus grand nombre. </MKTypography>

                            </div>


                        </Fade>


                        <Fade>

                            <div className="uline">

                                <Grid className="mobileicone" sx={{
                                    backgroundImage: `url(${shopbag})`,
                                }}></Grid>

                                <MKTypography className="textuline" variant="body2"> <strong>Publication en ligne</strong> <br></br> Si nécessaire, nous pouvons gérer la publication de votre application sur les différents magasins d'applications (Google Play ou App Store). </MKTypography>

                            </div>

                        </Fade>



                    </Grid>

                </Grid>



            </Container >



        </MKBox >
    );
}

export default Mobile;
