/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

// @mui material components
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Button from "@mui/material/Button";


function DefaultNavbarDropdown({
  name,
  icon,
  anchor,
  children,
  collapseStatus,
  light,
  href,
  visible,
  route,
  collapse,
  ...rest
}) {
  const linkComponent = {
    component: "a",
    href,
    target: "",
    rel: "noreferrer",
  };


  const routeComponent = {
    component: Link,
    to: route,
  };



  const hashLinkComponent = {
    component: HashLink,
    to: href,
  };

  if (!visible) {
    return <></>
  }

  return (
    <>
      <MKButton
        {...rest}
        mx={1}
        p={1}
        display="flex"
        alignItems="baseline"
        opacity={0.1}
        style={{ backgroundColor: "transparent", boxShadow:"none" }}
        component="a"
        variant="contained"
        
        sx={{ cursor: "pointer", userSelect: "none" }}
        {...(route && routeComponent)}
        {...((href && !anchor) && linkComponent)}
        {...(anchor && hashLinkComponent)}
      >
        <MKTypography
          variant="body2"
          lineHeight={1}
          color="info"
          sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
          
        >
          {icon}
        </MKTypography>
        <MKTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
          color="dark"
          sx={{ fontWeight: "100%", ml: 1, mr: 0, color:"white"}}
        >
          {name}
        </MKTypography>
        <MKTypography variant="body2" color={"white"} ml="auto">
          <Icon sx={{ fontWeight: "normal", verticalAlign: "middle" }}>
            {collapse && "keyboard_arrow_down"}
          </Icon>
        </MKTypography>
      </MKButton>
    
    </>
  );
}

// Setting default values for the props of DefaultNavbarDropdown
DefaultNavbarDropdown.defaultProps = {
  children: false,
  collapseStatus: false,
  light: false,
  anchor: true,
  href: "",
  route: "",
};

// Typechecking props for the DefaultNavbarDropdown
DefaultNavbarDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  anchor: PropTypes.bool,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  visible: PropTypes.bool,
  href: PropTypes.string,
  route: PropTypes.string,
  collapse: PropTypes.bool.isRequired,
};

export default DefaultNavbarDropdown;
