// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/team/yael.jpeg";
import team2 from "assets/team/louis.jpeg";

// Images
import bgImage from "assets/images/montpellier2.jpg";

import Fade from 'react-reveal/Zoom';

function Team() {
  return (
    <MKBox
      component="section"
      variant="contained"

      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      sx={{ borderRadius: "20px", marginLeft: "0.3%", marginRight: "0.3%" }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={13} sx={{ mb: 8 }}>
            <MKTypography variant="h3" color="#61aef0" sx={{textAlign: "center"}}>
              Qui sommes nous ?
              <br></br><br></br>
            </MKTypography>
            <MKTypography variant="body2" color="grey" opacity={1}>
              Nous sommes deux jeunes ingénieurs en informatique diplômés de l'IMT Mines Alès.
              Passionnés depuis toujours par les nouvelles technologies, nous avons décidé de partager notre passion en proposant notre expertise aux entreprises locales dans la région de Montpellier.
            </MKTypography>

          </Grid>
        </Grid>


        <Grid container>

          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <MKBox mb={1}>
                <Fade>
                  <HorizontalTeamCard
                    image={team1}
                    name="Yaël"
                    position={{ color: "info", label: "" }}
                    description="Passionné de nouvelles technologies, j'adore concevoir des applications web au design moderne."
                  />
                </Fade>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MKBox mb={1}>
                <Fade>
                  <HorizontalTeamCard
                    image={team2}
                    name="Louis"
                    position={{ color: "info", label: "" }}
                    description="Perfectionniste, curieux et passionné, je saurais répondre à vos besoins jusqu'au moindre détail."
                  />
                </Fade>
              </MKBox>
            </Grid>

          </Grid>

          <Grid>

            <Grid item xs={15} md={13} sx={{ mb: 8 }}>
              <MKTypography variant="h3" color="grey">
                <br></br>
              </MKTypography>

              <MKTypography variant="body2" color="grey" opacity={1}>
                Pour nous, la bonne entente avec nos clients est primordiale. C'est pourquoi nous favorisons toujours les échanges de qualité, dont les réunions en présentiel pour construire une relation de confiance.
              </MKTypography>
            </Grid>
          </Grid>

          <MKBox
            minHeight="30vh"
            width="100%"
            sx={{

              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.1),
                  rgba(gradients.dark.state, 0.6)
                )}, url(${bgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              display: "grid",
              placeItems: "center",
              borderRadius: "10px",
              bottom:"30px",
              position:"relative"
            }}
          />

        </Grid>

      </Container>
    </MKBox>
  );
}

export default Team;
