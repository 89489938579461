// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/macbook.png";

function Newsletter({showButton}) {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">Vous avez un projet ?</MKTypography>
            <MKTypography variant="body2" color="text" mb={5} style={{marginTop:"1rem"}}>
            {showButton ?
              <a>Contactez-nous afin que nous répondions à toutes vos questions.</a>

              :
              <a>              Pour tout renseignement, questions ou demande de devis, vous pouvez utiliser le formulaire ci-dessous pour nous contacter. Nous vous répondrons dans les plus brefs délais.
              </a>
            }
            </MKTypography>
            <Grid container spacing={1}>

              {showButton && 
                <Grid item xs={4}>
                <MKButton onClick={()=>window.location.replace("/presentation/#contact")} variant="gradient" color="info" sx={{ height: "100%" }}>
                  Nous contacter
                </MKButton>
              </Grid>
              }
              
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
