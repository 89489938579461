// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components

import googlesearch1 from "assets/images/illustrations/googlesearch1.png";
import googlecard2 from "assets/images/illustrations/googlecard2.jpg";
import plus from "assets/images/icones/plus.png";
import template from "assets/images/illustrations/website.jpg";

import Fade from 'react-reveal/Zoom';

import "./SEO.css";

function SEO() {


  return (
    <MKBox component="section" py={6}>
      <Container sx={{marginTop:"-40px", marginBottom: "-30px"}}>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Web"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            Création de sites et référencement
          </MKTypography>
          <MKTypography variant="body1" color="text">

          </MKTypography>
        </Grid>
      </Container>


      <Container className="main" sx={{ mt: { xs: 8, lg: 16 }, marginTop:"-50px"}}>


        <Fade>

          <Grid className="line" >

            <Grid className="linetop">

              <div className="blocLeft">
                <Grid className="textLeft" >
                  <div>
                    <div className="titlebloc">
                      <MKTypography className="title" variant="h5">Sites internet sur mesure</MKTypography>
                    </div>
                    <MKTypography variant="body2">Nous créons votre site à votre image, selon l'identité de votre entreprise.</MKTypography>
                    <MKTypography variant="body2">Nous vous proposons plusieurs modèles de sites ou créons le vôtre entièrement sur-mesure, selon vos besoins, même les plus complexes.</MKTypography>
                  </div>

                </Grid>


              </div>

              <Grid className="imageRight" sx={{
                backgroundImage: `url(${template})`,
              }}>

              </Grid>

            </Grid>

            <Grid className="linebottom">

              <Grid className="showmore" sx={{
                backgroundImage: `url(${plus})`,
              }}
              />
              <Grid className="contentbottom">


                <div className="showmoreline">
                  <a className="pastille"></a>
                  <MKTypography variant="body2">Types de sites proposés: mono-page, vitrine, sur-mesure, e-commerce</MKTypography>
                </div>

                <div className="showmoreline">
                  <a className="pastille"></a>
                  <MKTypography variant="body2">Nom de domaine, emails, hébergement et maintenance inclus</MKTypography>
                </div>

                <div className="showmoreline">
                  <a className="pastille"></a>
                  <MKTypography variant="body2">Modifiez votre site à tout moment en nous contactant</MKTypography>
                </div>

              </Grid>

            </Grid>


          </Grid>

        </Fade>


        <Fade>

          <Grid className="line" >

            <Grid className="linetop">

              <div className="blocLeft">
                <Grid className="textLeft">
                  <div>
                    <div className="titlebloc">
                      <MKTypography className="title" variant="h5">Référencement sur les moteurs de recherche</MKTypography>

                    </div>
                    <MKTypography variant="body2">Le référencement naturel est inclus dans notre offre de création de site Web.</MKTypography>
                    <MKTypography variant="body2">Grâce à ce référencement, vos clients peuvent trouver votre site dans les premiers résultats avec des mots clés ciblés.</MKTypography>
                  </div>
                </Grid>


              </div>

              <Grid className="imageRight" sx={{
                backgroundImage: `url(${googlesearch1})`,
              }}>

              </Grid>

            </Grid>

            <Grid className="linebottom">

              <Grid className="showmore" sx={{
                backgroundImage: `url(${plus})`,
              }}
              />

              <Grid className="contentbottom">


                <div className="showmoreline">
                  <a className="pastille"></a>
                  <MKTypography variant="body2">Optimisation de votre site pour un bon référencement sur les moteurs de recherche</MKTypography>
                </div>

                <div className="showmoreline">
                  <a className="pastille"></a>
                  <MKTypography variant="body2">Après la livraison de votre site, nous veillons à ce qu'il reste toujours bien référencé</MKTypography>
                </div>

              </Grid>

            </Grid>




          </Grid>


        </Fade>



        <Fade>


          <Grid className="line">

            <Grid className="linetop">

              <div className="blocLeft">
                <Grid className="textLeft">
                  <div>
                    <div className="titlebloc">
                      <MKTypography className="title" variant="h5">Etre visible en tant qu'entreprise sur Google</MKTypography>
                    </div>
                    <MKTypography variant="body2">Apparaissez tout en haut de la première page en tapant le nom de votre entreprise sur Google. En un coup d'oeil les internautes verront votre emplacement, vos photos, vos coordonnées ainsi que les avis sur votre entreprise.</MKTypography>
                  </div>
                </Grid>


              </div>


              <Grid className="imageRight" sx={{
                backgroundImage: `url(${googlecard2})`,
              }}>

              </Grid>

            </Grid>

            <Grid className="linebottom">

              <Grid className="showmore" sx={{
                backgroundImage: `url(${plus})`,
              }}
              />
              
              <Grid className="contentbottom">
                <div className="showmoreline">
                  <a className="pastille"></a>
                  <MKTypography variant="body2">Affichez votre adresse, horaires et avis tout en haut de la page grâce à l'encart Google</MKTypography>
                </div>
                

                <div className="showmoreline">
                  <a className="pastille"></a>
                  <MKTypography variant="body2">Mettez à jour les informations de votre entreprise à tout moment en nous contactant</MKTypography>
                </div>



              </Grid>

            </Grid>


          </Grid>

        </Fade>



      </Container>



    </MKBox>
  );
}

export default SEO;
