import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import BuiltByDevelopers from "pages/Home/components/BuiltByDevelopers";
import { Fade } from "react-reveal";
import "./style.css";
/*

action={{
                type: "internal",
                route: "#contact",
                label: "J'ai un projet",
              }}

*/

function Expertise() {
  return (
    <MKBox style={{ marginBottom: "6pc", marginTop: "0px"}}>
      <div className="isMobileSpace"></div>
      <Container sx={{ mt: 7}} style={{ display: "flex", justifyContent: "center"}}>
        <BuiltByDevelopers />
      </Container>
      <Container sx={{ marginTop: "20px"}}>
        <Grid style={{ display: "flex", justifyContent: "center" }} container spacing={3}>

            <Grid item xs={12} lg={5}>
            <Fade left>
              <FilledInfoCard
                variant="contained"
                color="info"
                icon="chat"
                title="Conseil"
                description="Tout au long du projet, nous analysons votre besoin pour vous proposer les solutions les plus économiques et performantes. Nous avançons toujours main dans la main avec nos clients en prônant le bon relationnel et la réactivité."

              />
              </Fade>
            </Grid>

          


          <Grid item xs={12} lg={5}>
          <Fade right>
          
            <FilledInfoCard
              variant="contained"
              color="info"
              icon="settings"
              title="Réalisation de votre projet"
              description="Nous utilisons les meilleures technologies actuelles pour vous fournir le produit adapté. D'un point de vue visuel et fonctionnel, nous créons la solution qu'il vous faut. Nos méthodes de travail flexibles nous permettent d'avancer ensemble."

            />

          </Fade>
          </Grid>

          <Grid item xs={12} lg={5}>
          
          <Fade right>
            <FilledInfoCard
              variant="contained"
              color="info"
              icon="computer"
              title="Infrastructure"
              description="Nous nous occupons aussi de la partie hébergement de votre projet et des données associées. Besoin d'un domaine, de serveurs, ou d'autres services d'infrastructures ? Nous nous occupons de tout."

            />
            
          </Fade>
          
          </Grid>

      

          <Grid item xs={12} lg={5}>
          <Fade left>
            <FilledInfoCard
              variant="contained"
              color="info"
              icon="precision_manufacturing"
              title="Maintenance"
              description="Nous fournissons la maintenance de votre produit après la livraison, ou plus longtemps selon vos besoins grâce à nos services de maintenance. Nous sommes donc là pour vous accompagner même après la fin du projet."

            />
            </Fade>
          </Grid>
          
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Expertise;
